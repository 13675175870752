import {
  CREATE_Notification_FAIL,
  CREATE_Notification_SUCCESS,
  CREATE_PROFILE_FAIL,
  CREATE_PROFILE_SUCCESS,
  CREATE_SLIDER_FAIL,
  CREATE_SLIDER_SUCCESS,
  DEL_Notification_FAIL,
  DEL_Notification_SUCCESS,
  DEL_SLIDER_FAIL,
  DEL_SLIDER_SUCCESS,
  GET_CUSTOMER_LIST_FAIL,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_Notification_FAIL,
  GET_Notification_SUCCESS,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS,
  GET_SLIDER_FAIL,
  GET_SLIDER_SUCCESS,
  UPDATE_Notification_FAIL,
  UPDATE_Notification_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_SLIDER_FAIL,
  UPDATE_SLIDER_SUCCESS,
  CUSTOMER_ENABLE_SUCCESS,
  CUSTOMER_ENABLE_FAIL
} from "../constants/settingConstants"
import { RESET_ERROR } from "../constants/userConstants"

export const SettingReducer = (state = { error: null }, action) => {
  switch (action.type) {
    case RESET_ERROR:
      return {
        ...state,
        error: null,
        profilecreated: null,
        profileupdated: null,
        notificationdeleted: null,
        notificationcreated: null,
        Notificationupdated: null,
        sliderlistcreated: null,
        sliderdeleted: null,
        sliderupdated: null,
        customerenabled: null
      }
    case CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        profilelist: action.payload,
        profilecreated: true
      }

    case CREATE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profilelist: action.payload,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profilelist: action.payload,
      };

    case GET_PROFILE_FAIL:
      return {
        ...state,
        errot: action.payload,
      };
    case GET_PROFILE_FAIL:
      return {
        ...state,
        errot: action.payload,
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profilelist: state?.profilelist,
        profileupdated: true,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profilelist: state?.profilelist,
        profileupdated: true,
      };

    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

        //*****************************NOTIFICATION***************************** */

    case GET_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: action.payload,
      };
    case GET_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: action.payload,
      };

    case GET_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: state.NotificationList
          ? [...state.NotificationList, action.payload]
          : [action.payload],
        notificationcreated: true,
      };
    case CREATE_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: state.NotificationList
          ? [...state.NotificationList, action.payload]
          : [action.payload],
        notificationcreated: true,
      };

    case CREATE_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DEL_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: state?.NotificationList.filter(
          (notif) => notif._id !== action.payload
        ),
        notificationdeleted: true,
      };
    case DEL_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DEL_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: state?.NotificationList.filter(
          (notif) => notif._id !== action.payload
        ),
        notificationdeleted: true,
      };
    case DEL_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: state?.NotificationList.map((notif) =>
          notif._id === action.payload._id ? action.payload : notif
        ),
        Notificationupdated: true,
      };
    case UPDATE_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: state?.NotificationList.map((notif) =>
          notif._id === action.payload._id ? action.payload : notif
        ),
        Notificationupdated: true,
      };
    case UPDATE_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /**********************SLIDER******************************************** */

    case GET_SLIDER_SUCCESS:
      return {
        ...state,
        getsliderlist: action.payload,
      };
    case GET_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_SLIDER_SUCCESS:
      return {
        ...state,
        getsliderlist: state.getsliderlist ? [...state.getsliderlist, action.payload] : [action.payload],
        sliderlistcreated: true,
      };
    case CREATE_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DEL_SLIDER_SUCCESS:
      return {
        ...state,
        getsliderlist: state?.getsliderlist.filter((notif) => notif._id !== action.payload),
        sliderdeleted: true,
      };
    case DEL_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_SLIDER_SUCCESS:
      return {
        ...state,
        getsliderlist: state?.getsliderlist.map((slider) => (slider._id === action.payload._id ? action.payload : slider)),
        sliderupdated: true,
      };

    case UPDATE_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    /**************************CUSTOMER**********************************/
    case GET_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        CustomerListdata: action.payload?.data, // List of customers for the current page
        totalCount: action.payload?.total, // Total number of customers for pagination
        error: null, // Reset error if success
      };

    case GET_CUSTOMER_LIST_FAIL:
      return {
        ...state,
        error: action.payload, // Set error in case of failure
        CustomerListdata: [], // Reset data if the request fails
        totalCount: 0, // Reset totalCount on failure
      };

    /**************************CUSTOMER ENABLE DISABLE**********************************/
    case CUSTOMER_ENABLE_SUCCESS:
      return {
        ...state,
        customerenabled: true,
      };

    case CUSTOMER_ENABLE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

        case GET_CUSTOMER_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            }
        /**************************CUSTOMER ENABLE DISABLE**********************************/
        case CUSTOMER_ENABLE_SUCCESS:
            return {
                ...state,
                customerenabled: true
            }

        case CUSTOMER_ENABLE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CUSTOMER_ENABLE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
};
