import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Button, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, ButtonGroup } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from "../common/CustomInputPast";
import { Getsalesreportsdata, GetSalesReportsDownloadData } from '../../redux/actions/reportsAction'; // Import your action here
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
const SalesReport = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([])
    const { salesReportsData } = useSelector((state) => state.reports); // Get total count from your Redux store
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const schema = yup.object({
        fromDate: yup.string().required("From date required"),
        toDate: yup.string().required("To date required"),
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
    });
    useEffect(() => {
        if(salesReportsData){
            if (salesReportsData?.totalOrdersCount!=0) {
                setData([salesReportsData]);
             // Assuming salesReportsData is an array
        }else{
            setData([]);
        }
        }
    }, [salesReportsData]);

    const subHeaderComponentMemo = React.useMemo(() => {
        const resetFun = () => {
            // Reset form fields explicitly
            reset({
                fromDate: null,
                toDate: null,
            });
            // Reset the state for filters
            setFromDate(null);
            setToDate(null);
            window.location.reload(); // Optional, only use if absolutely needed
        };
        const reportsDownload = (type) => {
            dispatch(GetSalesReportsDownloadData({ type: type, fromDate: fromDate, toDate: toDate }))
        };
        const filterOrders = (data) => {
            const fromDateFormatted = moment(data?.fromDate).format("YYYY-MM-DD");
            const toDateFormatted = moment(data?.toDate).format("YYYY-MM-DD");

            if (!data.toDate || !moment(data.toDate).isValid()) {
                toast.error(`To date is required `);
                return false;
            }
            if (!data.fromDate || !moment(data.fromDate).isValid()) {
                toast.error(`From date is required`);
                return false;
            }
            if (moment(toDateFormatted).isBefore(fromDateFormatted)) {
                toast.error(`To date cannot be earlier than from date`);
                return false;
            }
            setFromDate(fromDateFormatted); // Set state for fromDate
            setToDate(toDateFormatted); // Set state for toDate
            // Dispatch the action with formatted dates and reset the page to 1 for filtered results
            dispatch(Getsalesreportsdata({
                fromDate: fromDateFormatted,
                toDate: toDateFormatted
            }));
        };
        return (
            <div className="filter-wrapper"
            >
                <div className="input-cols-date">
                    <CustomInput
                        inputGroupClasses="date-input first-date-input"
                        fieldLabel="From Date"
                        control={control}
                        error={errors.fromDate}
                        fieldName="fromDate"
                        type={"date"}
                    />

                    <CustomInput
                        inputGroupClasses="date-input"
                        fieldLabel="To Date"
                        control={control}
                        error={errors.toDate}
                        fieldName="toDate"
                        type={"date"}
                    />
                    <Button
                        className="btn-sub"
                        type="button"
                        color="primary"
                        onClick={handleSubmit(filterOrders)}
                    >
                        Submit
                    </Button>
                    <Button className="btn-sub" type="button" color="primary" onClick={resetFun}>
                        Reset
                    </Button>
                </div>
                <div className="input-cols-download">
                    <ButtonGroup className="btn-subd">
                        <UncontrolledDropdown>
                            <DropdownToggle caret color="primary">
                                Download
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => reportsDownload('pdf')}><i className="fa fa-file-pdf-o down-load-icon" aria-hidden="true" ></i> Pdf</DropdownItem>
                                <DropdownItem onClick={() => reportsDownload('xlsx')}><i className="fa fa-file-excel-o down-load-icon" aria-hidden="true" style={{ fontSize: '20px', color: 'green' }}></i> Excel</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonGroup>
                </div>
            </div>
        );
    }, [control, errors, dispatch, reset]);

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    var columns = [
        {
            name: <b>{Capitalize('From Date')}</b>,
            header: <b>{Capitalize('From Date')}</b>,
            selector: (row) => `${moment(row?.fromDate, "DD-MM-YYYY").format("DD-MM-YYYY")}`,
            style: {
                textAlign: 'left',
            },
        },
        {
            name: <b>{Capitalize('To Date')}</b>,
            header: <b>{Capitalize('To Date')}</b>,
            selector: (row) => {
                return moment(row?.toDate, "DD-MM-YYYY").format("DD-MM-YYYY"); // Parse the input format
            },
            style: {
                textAlign: 'left',
            },
        },
        {
            name: <b>{Capitalize('Total Orders')}</b>,
            header: <b>{Capitalize('Total Orders')}</b>,
            style: {
                textAlign: 'left',
            },
            selector: (row) => row?.totalOrdersCount,
        },
        {
            name: <b>{Capitalize('Total Products')}</b>,
            header: <b>{Capitalize('Total Products')}</b>,
            style: {
                textAlign: 'left',
            },
            selector: (row) => row?.totalProductsSoldCount,
        },
        {
            name: <b>{Capitalize('Total Tax')}</b>,
            header: <b>{Capitalize('Total Tax')}</b>,
            selector: (row) => {
                // Format the currency value
                let formattedTaxAmount = new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR',
                    minimumFractionDigits: 2,
                }).format(row?.totalTaxAmount || 0);
                // Add a space after the currency symbol (₹)
                formattedTaxAmount = formattedTaxAmount.replace('₹', '₹ ');
                return formattedTaxAmount;
            },
            style: {
                textAlign: 'left',
            },
        },
        {
            name: <b>{Capitalize('Total Amount')}</b>,
            header: <b>{Capitalize('Total Tax Amount')}</b>,
            selector: (row) => {
                // Format the currency value
                let formattedTaxAmount = new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR',
                    minimumFractionDigits: 2,
                }).format(row?.totalSalesAmount || 0);
                // Add a space after the currency symbol (₹)
                formattedTaxAmount = formattedTaxAmount.replace('₹', '₹ ');
                return formattedTaxAmount;
            },
            style: {
                textAlign: 'left',
            },
        },
    ];
    // Function to handle data fetching with pagination parameters
    const fetchData = (fromDate, toDate) => {
        dispatch(Getsalesreportsdata({ fromDate, toDate }));
    };
    useEffect(() => {
        fetchData(fromDate, toDate);
    }, [fromDate, toDate]); // Add fromDate and toDate as dependencies
    return (
        <>
            <Breadcrumb title="Sales reports" parent="Reports" />
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical sales-list">
                                    <DataTable
                                        columns={columns}
                                        data={Array.isArray(data) ? data : []} // Ensure data is always an array
                                        fixedHeader
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default SalesReport;

