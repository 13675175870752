
import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import { useDispatch, useSelector } from "react-redux";
import { DeleteCategory, GetCategory } from "../../../redux/actions/productActions";
import DataTables from "../../common/DataTables";
import { toast } from 'react-toastify';
import { RESET_ERROR } from "../../../redux/constants/userConstants";
import DataTable from "react-data-table-component";
import SearchHeader from "../../common/header_components/searchHeader";

const ProductCategory = () => {
    const dispatch = useDispatch()
    const [datas, setDatas] = useState([])
    const { getcatogrylist, categorydelsuccess, editedcategory, newcategory,error } = useSelector(state => state.product)
    const [open, setOpen] = useState(false);
    const [openedit, setOpenEdit] = useState(false);
    const [activeData, setActiveData] = useState(null)
    const [title, setTitle] = useState('')
    const [filterText, setFilterText] = useState('');

    const customToast = "delCat";
    const customCToast = "delCatC";
    const customUToast = "delCatU";

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    const handleDelete = (id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            dispatch(DeleteCategory(id))
        }


    }

    const handleEdit = (data) => {
        setTitle("Edit Category");
        setActiveData(data)
        setOpen(true)

    }

    const onOpenModal = () => {
        setTitle('Add Category')
        setActiveData(null)
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    const onOpenEditModal = () => {
        setOpenEdit(true);
    };

    const onCloseEditModal = () => {
        setOpenEdit(false);
    };
    useEffect(() => {
        dispatch(GetCategory())
    }, [])

    useEffect(() => {
        if (getcatogrylist) {
            setDatas(getcatogrylist)
        }
    }, [getcatogrylist])

    useEffect(() => {
        if (categorydelsuccess) {
            toast.success("Category Deleted successfully",{toastId: customToast});
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [categorydelsuccess])


    useEffect(() => {
        if (newcategory) {
            toast.success("Category Created successfully",{toastId: customCToast});
            dispatch({
                type: RESET_ERROR
            })
            setActiveData(null);
            setOpen(false);
            setTitle('')
        }

    }, [newcategory])


    useEffect(() => {
        if (editedcategory) {
            toast.success("Category Updated successfully",{toastId: customUToast});
            dispatch({
                type: RESET_ERROR
            })
            setActiveData(null);
            setOpen(false);
            setTitle('')

        }

    }, [editedcategory])


    useEffect(() => {
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error])

    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Image")}</b>,
            header: <b>{Capitalize("Image")}</b>,
            cell: (row, index) => (
                <>
                    <span>
                        <img style={{ width: 40, height: 40 }} src={row?.image}></img>
                    </span>
                </>

            )
        },
        {
            name: <b>{Capitalize("Category Name")}</b>,
            header: <b>{Capitalize("Category Name")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Description")}</b>,
            header: <b>{Capitalize("description")}</b>,
            selector: row => row?.description,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Unit")}</b>,
            header: <b>{Capitalize("Unit")}</b>,
            selector: row => row?.unit?.map((res)=>res?.name).join(','),
            style: {
                textAlign: "center",
            },
        },
         {
            name: <b>{Capitalize("Gst")}</b>,
            header: <b>{Capitalize("Gst")}</b>,
            selector: row => row?.tax,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => handleDelete(row._id)} >

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span onClick={() => handleEdit(row)}>
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setFilterText('');
			}
		};

		return (
            <div className="table-header">
                 <div className="btn-popup pull-right">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"
                                    >
                                        Add Category
                                    </Button>
                                </div>
                <SearchHeader onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            </div>
		);
	}, [filterText]);

    useEffect(() => {
        // if(filterText){
            let datas = getcatogrylist?.filter(cat => cat?.name?.toLowerCase().includes(filterText?.toLowerCase()) || cat?.description?.toLowerCase().includes(filterText?.toLowerCase()) || cat?.unit?.map(un => un.name.toLowerCase()).join(',').includes(filterText?.toLowerCase()))
            console.log({datas})
            setDatas(datas)
        // }
    }, [filterText])

    return (
        <Fragment>
            <Breadcrumb title="Category" parent="Products" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                               
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <DataTable
                                        pagination columns={columns}
                                        data={datas}
                                        fixedHeader  
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}  
                                    />

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddCategory 
                openModal={open} 
                onCloseModal={() => {
                    setActiveData(null)
                    setOpen(false)
                }}
                data={activeData} 
                setActiveData={setActiveData} 
                title={title}
            />


        </Fragment>
    )
}

export default ProductCategory