import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerEnableDisable, GetCustomerList } from '../../redux/actions/SettingActions';
import DataTable from 'react-data-table-component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ToggleSwitch from '../common/ToggleSwitch';
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../redux/constants/userConstants';
import SearchHeader from '../common/header_components/searchHeader';

const CustomerList = () => {
  const dispatch = useDispatch();
  const { CustomerListdata, totalCount, customerenabled } = useSelector((state) => state.settings); // Get total count from your Redux store
  // Local state for pagination and rows per page
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0); // Total rows from the server
  const [localStatus, setLocalStatus] = useState({});
  const [filterText, setFilterText] = useState(''); // New search query state

  useEffect(() => {
    if (customerenabled) {
      dispatch({ type: RESET_ERROR });
    }
  }, [customerenabled, dispatch]);

  useEffect(() => {
    if (CustomerListdata) {
      // Initialize localStatus with current data
      const statusMap = {};
      CustomerListdata?.data?.forEach((row) => {
        statusMap[row._id] = row.status;
      });
      setLocalStatus(statusMap);
    }
  }, [CustomerListdata?.data]);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const columnStyles = {
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const enabledTooltip = (
    <Tooltip id="enabled-tooltip" className="custom-tooltip">
      Enabled
    </Tooltip>
  );

  const disabledTooltip = (
    <Tooltip id="disabled-tooltip" className="custom-tooltip">
      Disabled
    </Tooltip>
  );

  const handleToggle = (rowId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    const formdata = {
      id: rowId,
      status: newStatus,
    };
    // Update local status for immediate feedback
    setLocalStatus((prev) => ({ ...prev, [rowId]: newStatus }));

    dispatch(CustomerEnableDisable(formdata))
      .then(() => {
        toast.success('Customer status updated successfully');
      })
      .catch(() => {
        // Revert local status on error
        setLocalStatus((prev) => ({ ...prev, [rowId]: currentStatus }));
      });
  };

  var columns = [
    {
      name: <b>{Capitalize('Name')}</b>,
      header: <b>{Capitalize('Name')}</b>,
      selector: (row) => row?.name,
      style: {
        textAlign: 'left',
      },
    },
    {
      name: <b>{Capitalize('Email')}</b>,
      header: <b>{Capitalize('Email')}</b>,
      selector: (row) => row?.email,
      style: {
        textAlign: 'left',
      },
    },
    {
      name: <b>{Capitalize('Address')}</b>,
      header: <b>{Capitalize('Address')}</b>,
      style: {
        textAlign: 'left',
      },
      cell: (row, index) => (
        <>
          <span>
            {row?.address?.addressLine1},{row?.address?.addressLine2},{row?.address?.country},{row?.address?.city},{row?.address?.state},{row?.address?.zip},
          </span>
        </>
      ),
    },
    {
      name: <b>{Capitalize('Mobile')}</b>,
      header: <b>{Capitalize('Mobile')}</b>,
      selector: (row) => row?.mobile,
      style: {
        textAlign: 'left',
      },
    },
    {
      name: <b>{Capitalize('Status')}</b>,
      header: <b>{Capitalize('Status')}</b>,
      cell: (row) => (
        <OverlayTrigger placement="top" overlay={localStatus[row._id] === 'active' ? enabledTooltip : disabledTooltip}>
          <div style={columnStyles}>
            <ToggleSwitch isActive={localStatus[row._id] === 'active'} onToggle={() => handleToggle(row._id, localStatus[row._id])} />
          </div>
        </OverlayTrigger>
      ),
      style: { textAlign: 'center' },
    },
  ];
  // Function to handle data fetching with pagination parameters
  const fetchData = (page, rowsPerPage, filterText) => {
    dispatch(GetCustomerList({ page, perPage: rowsPerPage, filterText }));
  };
  // Update pagination and fetch data whenever page or rowsPerPage changes
  useEffect(() => {
    fetchData(page, rowsPerPage, filterText);
  }, [page, rowsPerPage]);

  useEffect(() => {
    // Set total rows from the response (if provided by API)
    if (totalCount) {
      setTotalRows(totalCount);
    }
  }, [totalCount]);

  // Function to handle page changes
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Function to handle rows per page change
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1); // Reset to page 1 whenever rows per page changes
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
    setPage(1); // Reset to page 1 when the search query changes
    // Call fetchData immediately after updating the filterText
    if (value.length > 2) {
      fetchData(1, rowsPerPage, value); // Ensure the API gets the latest filterTex
    }
  };
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText('');
        fetchData(1, rowsPerPage, ''); // Ensure to pass the empty string as the filterText
      }
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'end', flex: 1 }}>
        <SearchHeader
          filterText={filterText}
          onFilter={handleSearchChange} // Pass the onChange handler
          onClear={handleClear} // Clear the search filter
          inputStyle={{ float: 'left', marginBottom: '10px' }}
        />
      </div>
    );
  }, [filterText, rowsPerPage]);
  return (
    <Fragment>
      <Breadcrumb title="Customer" parent="Customer" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid="true">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical customer-list">
                  <DataTable
                    columns={columns}
                    data={Array.isArray(CustomerListdata?.data) ? CustomerListdata?.data : []} // Ensure data is always an array
                    pagination
                    paginationServer
                    paginationTotalRows={CustomerListdata?.total} // Total rows from server
                    onChangePage={handlePageChange} // Handle page change
                    onChangeRowsPerPage={handleRowsPerPageChange} // Handle rows per page change
                    paginationRowsPerPageOptions={[10, 15, 25, 50]} // Page size options
                    paginationPerPage={rowsPerPage} // Initial page size
                    paginationComponentOptions={{
                      rowsPerPageText: 'Rows per page:',
                      rangeSeparatorText: 'on',
                    }}
                    fixedHeader
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CustomerList;

