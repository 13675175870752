import axios from '../../customAxios';
import {
    GET_REPORTS_LIST_SUCCESS,
    GET_REPORT_LIST_FAIL,
    GET_DOWNLOAD_DATA_SUCESS,
    GET_DOWNLOAD_DATA_FAIL,
    GET_REPORTS_SALES_SUCCESS,
    GET_REPORTS_SALES_FAIL,
    LOADING
} from '../constants/reportsConstants.js';

export const GetreportsListdata = ({ page, perPage, fromDate, toDate }) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .post(`/admin/reports/tax-report?`, {
            // Send data in the request body
            page: page, // Current page number
            limit: perPage, // Number of items per page
            fromDate: fromDate,
            toDate: toDate,
        })
        .then((response) => {
            const { data, total } = response?.data?.data?.orders; // Assume API returns `data` and `total` count
            dispatch({
                type: GET_REPORTS_LIST_SUCCESS,
                payload: {
                    data, // List of reports for the current page
                    total, // Total number of reports (for pagination)
                },
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_REPORT_LIST_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};
export const GetReportsDownloadData = ({ type, fromDate, toDate }) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    try {
        const response = await axios.post(`admin/reports/tax-report/download?`, {
            type: type,
            fromDate: fromDate,
            toDate: toDate,
        }, {
            responseType: 'blob' // Set responseType to blob here
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.setAttribute('download', `tax-report.${type}`);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.remove();

        dispatch({
            type: GET_DOWNLOAD_DATA_SUCESS, // Ensure success type is correct
            payload: response
        });
    } catch (err) {
        dispatch({
            type: GET_DOWNLOAD_DATA_FAIL,
            payload: err,
        });
    } finally {
        dispatch({
            type: LOADING,
            payload: false,
        });
    }
};
export const Getsalesreportsdata = ({ fromDate, toDate }) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .post(`admin/reports/sales-report?`, {
            fromDate: fromDate,
            toDate: toDate,
        })
        .then((response) => {
            console.log(response.data)
            dispatch({
                type: GET_REPORTS_SALES_SUCCESS,
                payload: response?.data?.data
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_REPORTS_SALES_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};
export const GetSalesReportsDownloadData = ({ type, fromDate, toDate }) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    try {
        const response = await axios.post(`admin/reports/sales-report/download?`, {
            type: type,
            fromDate: fromDate,
            toDate: toDate,
        }, {
            responseType: 'blob' // Set responseType to blob here
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.setAttribute('download', `sales-report.${type}`);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch({
            type: GET_DOWNLOAD_DATA_SUCESS, // Ensure success type is correct
            payload: response
        });
    } catch (err) {
        dispatch({
            type: GET_DOWNLOAD_DATA_FAIL,
            payload: err,
        });
    } finally {
        dispatch({
            type: LOADING,
            payload: false,
        });
    }
};

