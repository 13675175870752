import React, { useState, useEffect } from "react";
import { Button, Table } from "reactstrap";
import ProfileModal from "./ProfileModal";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../redux/actions/SettingActions";
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../../redux/constants/userConstants';
const TabsetProfile = () => {
	const { profilelist, error, profileupdated } = useSelector(state => state.settings);
	
	const dispatch = useDispatch()

	const [open, setOpen] = useState(false);
	const [edit, setedit] = useState(false)

	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};

	useEffect(() => {
		dispatch(getProfile()) 
	}, [open])

	useEffect(() => {
		if (profileupdated) {
			toast.success("Profile updated successfully");
			dispatch({
				type: RESET_ERROR
			})
			onCloseModal()
		}
	}, [profileupdated])
	useEffect(() => {
		if (error) {
			toast.warning(`${error.message}`);
			dispatch({
				type: RESET_ERROR
			})
		}
	}, [error])
	return (
		<div>
			<div style={{ display: "flex", justifyContent: "flex-end" }}><Button onClick={() => (
				onOpenModal(),
				setedit(true)
			)}>EDIT PROFILE</Button></div>
			<div className="tab-pane fade show active mt-3">
				<div className="table-responsive profile-table">
					<Table bordered className="table-responsive profile-table">
						<tbody>
							<tr>
								<td>Store Name:</td>
								<td>{profilelist?.storename}</td>
							</tr>
							<tr>
								<td>Store Email:</td>
								<td>{profilelist?.storeemail}</td>
							</tr>
							<tr>
								<td>Address</td>
								<td>{profilelist?.address}</td>
							</tr>
							<tr>
								<td>Mobile</td>
								<td>{profilelist?.mobile}</td>
							</tr>
							<tr>
								<td>Gst:</td>
								<td>{profilelist?.gst}</td>
							</tr>
							<tr>
								<td>Maximum Order Quantity:</td>
								<td>{profilelist?.maximum_order_quantity}</td>
							</tr>
							<tr>
								<td>Delivery Charge:</td>
								<td>{profilelist?.delivery_charge}</td>
							</tr>
							<tr>
								<td>Delivery Slot:</td>
								<td>{profilelist?.delivery_slot === 1 ? 'Enabled' : 'Disabled'}</td>
							</tr>
							<tr>
								<td>Primary Color:</td>
								<td>{profilelist?.primary_color}</td>
							</tr>
							<tr>
								<td>Secondary Color:</td>
								<td>{profilelist?.secondary_color}</td>
							</tr>
							<tr>
								<td>Tertiary Color:</td>
								<td>{profilelist?.tertiary_color}</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>
			<ProfileModal openModal={open} onCloseModal={() => {
				setOpen(false)
			}} dataList={profilelist} error={error} />

		</div>
	);
};

export default TabsetProfile;
