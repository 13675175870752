import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import unitdata from '../unit/unitdata';
import SelectInput from '../../common/SelectInput';
import { useDispatch, useSelector } from "react-redux";
import { GetCategory, getuniltList } from '../../../redux/actions/productActions';
import moment from 'moment';

const AddUnitmodal = ({ openModal, onCloseModal, func, unitlistmap }) => {

    const dispatch = useDispatch();
    const { unitList, unitDeleteSuccess, newUnit, updatedunit, getcatogrylist, } = useSelector(state => state.product)
    const [unit, setUnit] = useState('');
    const [start, setstart] = useState('');
    const [end, setend] = useState('');
    const [offer, setOffer] = useState(false);


    const changeUnit = (event) => {
        setUnit(event.target.value);
    };
    // const schema = yup.object({
    //     name: yup.string().required("Name field is required"),
    //     Regular_Price: yup.number()
    //         .transform((value, originalValue) => (originalValue === '' ? undefined : Number(originalValue)))
    //         .positive("The number must be greater than 0!")
    //         .required("Purchase price is required"),
    //     Selling_Price: yup.number()
    //         .transform((value, originalValue) => (originalValue === '' ? undefined : Number(originalValue)))
    //         .positive("The number must be greater than 0!")
    //         .required("Selling price is required")
    //         .test(
    //             'is-greater',
    //             'Selling price must be greater than purchase price',
    //             function (value) {
    //                 const { Regular_Price } = this.parent;
    //                 return value > Regular_Price;
    //             }
    //         ),
    //     unit_id: yup.object().required("Please select unit").typeError("Please select unit"),
    //     // Offer_Price:yup.string().test(
    //     //     'Is positive?', 
    //     //     ' The number must be greater than 0!', 
    //     //     (value) => value > 0
    //     //   )

    // }).required();

    const schema = yup.object({
        name: yup.string().required('Variant field is required'),
        Regular_Price: yup.number()
            .transform((value, originalValue) => (originalValue === '' ? undefined : Number(originalValue)))
            .positive('The number must be greater than 0!')
            .required('Maximum Retail Price is required'),
        Selling_Price: yup.number()
            .transform((value, originalValue) => (originalValue === '' ? undefined : Number(originalValue)))
            .positive('The number must be greater than 0!')
            .required('Selling price is required')
            .test(
                'is-greater',
                'Selling price must be less than or equal Maximum Retail Price',
                function (value) {
                    const { Regular_Price } = this.parent;
                    // if (value === undefined || value === null) {
                    //     return true;  // Skip validation if Selling_Price is not provided
                    // }
                    return value <= Regular_Price;
                }
            ),
        Offer_Price: yup.mixed().when('offer', {
            is: true,
            then: yup.number()
                .transform((value, originalValue) => (originalValue === '' ? undefined : Number(originalValue)))
                .positive('The number must be greater than 0!')
                .required('Offer price is required when offer is enabled')
                .test(
                    'is-lower-than-regular',
                    'Offer price must be lower than selling price',
                    function (value) {
                        const { Selling_Price } = this.parent;
                        return value < Selling_Price;
                    }
                )
                .test(
                    'is-lower-than-selling',
                    'Offer price must be lower than selling price',
                    function (value) {
                        const { Selling_Price } = this.parent;
                        if (Selling_Price === undefined || Selling_Price === null) {
                            return true;  // Skip this test if Selling_Price is not provided
                        }
                        return value < Selling_Price;
                    }
                ),
            otherwise: yup.mixed().notRequired(),
        }),
        ...(offer ? {
            EndDate: yup.date().required("End date is required").typeError("End date is required"),
            startDate: yup.date().required("Start date is required").typeError("Start date is required")
        } : {}),
        unit_id: yup.object().required('Please select unit').typeError('Please select unit'),
        offer: yup.boolean().default(false),  // Adding this to handle the checkbox state
    }).required();



    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            unit_id: '',
            name: '',
            Selling_Price: '',
            Regular_Price: '',
            Offer_Price: '',
            startDate: '',
            EndDate: '',
            offer: false,
        }
    });


    const offerselect = (e, checked) => {
        setOffer(checked);
        setValue('offer', checked);
        if (checked) {
            setValue('startDate', null);
            setValue('EndDate', null);
        } else {
            setValue('Offer_Price', '');
        }
    };

    const transferValue = (event) => {
        // event.preventDefault()


        const val = {
            uid: moment().unix(),
            id: event.unit_id,
            name: event.name,
            description: '',
            selling: event.Selling_Price,
            regular: event.Regular_Price,
            offer: event.Offer_Price,
            fromDate: event.startDate,
            toDate: event.EndDate
        };
        //console.log({val})
        func(val);
        reset()
        setOffer(false)
        onCloseModal();

    };

    // useEffect(() => {
        // dispatch(getuniltList())
        // dispatch(GetCategory())
    // }, [])



    const handlechangestartdate = (date) => {

        setstart(date)
    }
    const handlechangeEnddate = (date) => {
        setend(date)
    }
    const getcategoryid = (value) => {
    }

    const cleardata = () => {
        reset()
        onCloseModal()
    }


    return (
        <Modal isOpen={openModal} toggle={cleardata}>
            <ModalHeader toggle={cleardata}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    Add Product Variant
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <SelectInput
                        fieldLabel='Unit'
                        control={control}
                        error={errors.unit_id}
                        fieldName='unit_id'
                        options={unitlistmap}
                    />
                    <div>
                        <CustomInput
                            fieldLabel={'Variant Value:'}
                            control={control}
                            error={errors.name}
                            fieldName={'name'}
                        />
                    </div>
                    {/* <div>

                        <CustomInput
                            fieldLabel='Variant Description'
                            control={control}
                            error={errors.Variant_Description}
                            fieldName={'Variant_Description'}
                        />
                    </div> */}

                    <div>

                        <CustomInput
                            fieldLabel='Maximum Retail Price'
                            control={control}
                            error={errors.Regular_Price}
                            fieldName={'Regular_Price'}
                            type="number"
                        />
                    </div>
                    <div>

                        <CustomInput
                            fieldLabel='Selling Price'
                            control={control}
                            error={errors.Selling_Price}
                            fieldName={'Selling_Price'}
                            type="number"
                        />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "10px", }}>
                        <input type="checkbox" onChange={(e) => offerselect(e.target.value, e.target.checked)} style={{ cursor: "pointer" }}></input>
                        <label>Add Offer Price</label>

                    </div>
                    {offer ? <div>

                        <CustomInput
                            fieldLabel='Offer Price'
                            control={control}
                            error={errors.Offer_Price}
                            fieldName={'Offer_Price'}
                            type="number"
                        />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>


                            <CustomInput
                                fieldLabel={'Start Date'}
                                control={control}
                                error={errors.startDate}
                                fieldName={'startDate'}
                                type={'date'}
                                minimum={moment().format("YYYY-MM-DD")}
                            />

                            <CustomInput
                                fieldLabel={'End Date'}
                                control={control}
                                error={errors.EndDate}
                                fieldName={'EndDate'}
                                type={'date'}
                            />
                        </div>

                    </div>

                        : ""}

                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(transferValue)}
                >
                    Save
                </Button>
                <Button
                    type="button"
                    color="secondary"
                    onClick={onCloseModal}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default AddUnitmodal